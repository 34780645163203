import React from 'react'
import { hot } from 'react-hot-loader/root'
import { StripeProvider,Elements, CardElement } from 'react-stripe-elements'
import Billing from './Billing'

const Entry = props => {
  return (
    <StripeProvider stripe={props.stripe}>
      <Elements>
        <Billing stipe={props.stripe} />
      </Elements>
    </StripeProvider>
  )
}

export default hot(Entry)
