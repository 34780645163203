import React, {useState} from 'react'
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core'


const TermsModal = props => {
    let bullet_1;

    if (props.company !== '') {
        bullet_1 = `1. This payment submission is in relation to the terms and conditions of service agreed between ${props.company} (“the Client”) and the Arup entity identified in the invoice (“Arup”) for the items summarized on the respective invoice/s to which this payment relates (the “Invoice/s”).`
    } else {
        bullet_1 = `1. This payment submission is in relation to the terms and conditions of service agreed between the user of this portal (“the Client”) and the Arup entity identified in the invoice (“Arup”) for the items summarized on the respective invoice/s to which this payment relates (the “Invoice/s”).`
    }

    return (
        <Dialog aria-labelledby="terms-dialog" aria-describedby="terms-dialog" open={props.modalopen}
                onClose={props.closeModal}>
            <DialogTitle variant="h6">
                Credit Card Payment Terms
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    By clicking “Process Payment” you are agreeing to the following;
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primaryTypographyProps={{variant: "body2"}}
                                      primary={
                                          <div>
                                              {bullet_1}
                                          </div>
                                      }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primaryTypographyProps={{variant: "body2"}}
                                      primary={
                                          <div>
                                              2. The Client is hereby notified and understands that Arup has engaged the
                                              services of a third-party payment gateway in the provision of credit card
                                              payments. The provider is Stripe (“Stripe”). The Client agrees to provide
                                              the information required to
                                              be provided through this gateway, which is maintained according to the
                                              privacy conditions of Stripe which can be viewed at <a
                                              href="https://stripe.com/privacy"
                                              target="_blank">https://stripe.com/privacy</a> and the privacy policy
                                              of Arup which can be viewed at <a href="https://www.arup.com/legal"
                                                                                target="_blank">https://www.arup.com/legal</a>
                                          </div>
                                      }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primaryTypographyProps={{variant: "body2"}}
                                      primary={
                                          <div>
                                              3. The individual submitting this payment on behalf of the Client
                                              acknowledges and warrants that they are the card holder and/or are duly
                                              authorized to submit payments on behalf of the Client.
                                          </div>
                                      }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primaryTypographyProps={{variant: "body2"}}
                                      primary={
                                          <div>
                                              4. The Client agrees to the charges appearing on the Invoice/s and
                                              authorizes Stripe to process payment on your nominated credit/debit card
                                              for the invoiced amount and any additional amounts (including processing
                                              fees, as applicable). Additional amounts will be detailed on the page once
                                              the Client clicks "Review - Calculate Total" and before the Client clicks
                                              "Process Payment". </div>
                                      }
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemText primaryTypographyProps={{variant: "body2"}}
                                      primary={
                                          <div>
                                              5. The Client understands that the final amount which will be processed,
                                              will be processed in the currency shown on the payment submission page and
                                              any currency conversion amounts incurred by the Client due to
                                              international credit/debit card usage will be borne by the Client and
                                              should be discussed between the Client and their banking
                                              institution. </div>
                                      }
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemText primaryTypographyProps={{variant: "body2"}}
                                      primary={
                                          <div>
                                              6. The Client also agrees that there are sufficient, cleared funds
                                              available on the provided credit/debit card account to cover all amounts
                                              due. The Client is responsible for the timely payment of all invoices due
                                              to Arup and for providing Stripe (on behalf of Arup) with a valid payment
                                              method for all invoices and associated fees. Any dishonor fees incurred by
                                              Arup will be on-charged to the Client and must be subsequently settled by
                                              the Client within seven (7) days. </div>
                                      }
                        />
                    </ListItem>

                </List>
                <Typography variant="h6">
                    Inquiries and Disputes
                </Typography>
                <Typography variant="body2">
                    If you have any inquiries or disputes, please contact the Arup project manager that you're working
                    with. All communication with us should include your invoice number and any other details which will
                    assist us in responding to your inquiry. </Typography>
                {/*<Typography variant="h6">*/}
                {/* Disputes*/}
                {/*</Typography>*/}
                {/*<Typography variant="body2">*/}
                {/* If you believe that a charge has been processed incorrectly, we encourage you to take the matter up directly with us by contacting <a href="mailto:aus.receivable@arup.com">aus.receivable@arup.com</a> or call +61 (0) 2 9320 9320 and ask for Accounts Receivable during business hours.*/}
                {/*</Typography>*/}
            </DialogContent>
        </Dialog>
    )
}

export default TermsModal
