import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    // padding: '16px',
    fontSize: '14px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'columns',
    maxWidth: '80vw'
  },
  button: {
    margin: '8px',
  },
  form: {
    width: '80vw'
  },
  fullContainers: {
    height: '100vh',
  },
  appBar: {
    boxShadow: theme.shadows[1],
  },
  Typography: {
    verticalAlign: 'bottom'
  },
  TextField: {
    marginBottom:'8px',
    width: 200
  },
  paper: {
    padding: theme.spacing(2),
    //borderRadius: '0px',
    margin: '5px',
    backgroundColor: 'none',
    //border: 0,
    // boxShadow: 'none',
  },
  media: {
    height: 'auto',
    width: 'auto',
    maxHeight: '30px',
    marginLeft: '10%'
  },
  right: {
    textAlign: 'right'
  },
  // modal: {
  //   position: 'absolute',
  //   width: 768,
  //   backgroundColor: theme.palette.background.paper,
  //   border: '2px solid #000',
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 4),
  //   outline: 'none',
  //   top: '5%',
  //   left: '10%',
  //   overflow: 'auto',
  //   overflowY: 'scroll',
  //   overflowX: 'hidden',
  //   height: 'calc(100% - 200px)'
  // }
}))
