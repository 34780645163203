import React, {useState} from 'react'
import {
    AppBar,
    Button,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {HelpOutline} from '@material-ui/icons'
import NumberFormat from 'react-number-format';
import {CardElement, injectStripe} from 'react-stripe-elements';

import useForm from '../hooks/useForm';
import styles from './Styles';
import TermsModal from './TermsModal';
import CircularProgress from "@material-ui/core/CircularProgress";

let error;
let errors = {
    cardholder: false,
    phone: false,
    company: false,
    email: false,
    address_line1: false,
    address_line2: false,
    address_city: false,
    address_zip: false,
    address_state: false,
    address_country: false,
    invoice_id: false,
    job_number: false,
    amount: false,
};


const invalidCharacters = [
    '&',
    '|',
    ';',
    '$',
    '%',
    '>',
    '<',
    '{',
    '}',
    '\\',
    '\'',
    '\"',
    '!',
    '`',
    '~'
]

const currencyStyle = {
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    lineHeight: "1.75",
};

const getFeeInfo = async () => {
    const country = await fetch("http://ip-api.com/json")
        .then(response => response.json())
        .then(data => {
            return data['countryCode']
        });
    let copy = [], feeInfo;
    if (country === "CA") {
        feeInfo = {
            "rate_international": 0.039,
            "rate_domestic": 0.029,
        };
        copy[0] = `${+(feeInfo.rate_international * 100).toFixed(2)}% for all transactions`;
        return {
            ...feeInfo,
            copy
        }
    }
    feeInfo = {
        "rate_international": 0.039,
        "rate_domestic": 0.029,
    };
    copy[0] = `${+(feeInfo.rate_domestic * 100).toFixed(2)}% for domestic cards (${countryCode})`;
    copy[1] = `${+(feeInfo.rate_international * 100).toFixed(2)}% for international cards (non ${countryCode})`;
    return {
        ...feeInfo,
        copy
    }
};

const Billing = props => {
    const [process, setProcess] = useState({
        processing: false,
        failed: false,
        international: false,
        complete: false
    })


    const [response, setResponse] = useState('');
    const [cardToken, setCardToken] = useState(null);
    const [modalopen, setModalOpen] = useState(false);
    const [feemodalopen, setFeeModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const classes = styles();

    const processPayment = (token) => {
        // console.log(token)
        let charge = {
            customer: {
                address: {
                    line1: values.address_line1,
                    line2: values.address_line2,
                    city: values.address_city,
                    country: token.card.country,
                    postal_code: values.address_zip,
                    state: values.address_state
                },
                source: token.id,
                email: values.email,
                name: values.name,
                phone: values.phone
            },
            invoice: {
                currency: ('USD').toLowerCase(),
                amount: Math.round(values.amount * 100),
                description: `Services described in manual invoice: ${values.invoice_id}`
            },
            fee: {
                currency: ('USD').toLowerCase(),
                amount: Math.round(values.fees * 100),
                description: `Payment processing fee`
            },
            metadata: {
                invoice_id: values.invoice_id,
                job_number: values.job_number,
                company: values.company
            }
        };
        fetch("https://f33jktq49k.execute-api.us-east-1.amazonaws.com/prod/api", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(charge)
        })
            .then(res => {
                return res.json()
            })
            .then(response => {
                console.log(response);
                setProcess(p => ({...p, processing: false}))
                console.log('done')
                if (response.paid) {
                    setProcess(p => ({...p, complete: true, processing: false, failed: false}));
                    setResponse(response)
                    error = null;
                } else {
                    setProcess(p => ({...p, complete: false, processing: false, failed: true}));
                    error = response.raw.message
                    setResponse(response)
                }
            })
            .catch(e => {
                setProcess(p => ({...p, processing: false, failed: true}));
                setResponse(response);
                console.log(e)
            })
    };

    const doPayment = async () => {
        error = null;
        if (checkErrors(errors)) {
            setProcess(p => ({...p, processing: true, failed: false}));
            try {
                if (props.stripe) {
                    const {token, error} = await props.stripe.createToken();
                    if (error) {
                        setResponse(error.message);
                        throw new Error(error.code, error.message)
                    }
                    setCardToken(token);
                    processPayment(token);
                } else {
                    console.log("Stripe.js hasn't loaded yet.");
                }
            } catch (e) {
                console.log(e);
                error = e
                setProcess(p => ({...p, processing: false, failed: true}))
                // error=e;
            }
        } else {
            console.log(errors)
            setProcess(p => ({...p, processing: false, failed: true}))
        }
    };

    const feeCalc = () => {
        const flatFee = 0.3;
        const percent = .029;
        const total_fees = ((1 / (1 - percent)) * (parseFloat(values.amount) + flatFee)) - parseFloat(values.amount);
        values.fees = total_fees;
        values.total = total_fees + value.amount
        return total_fees.toFixed(2)
    };

    const {values, handleChange, handleSubmit} = useForm(doPayment);

    function SimpleDialog(props) {
        const {onClose, selectedValue, open} = props;

        const handleClose = () => {
            onClose(selectedValue);
        };

        const handleListItemClick = (value) => {
            onClose(value);
        };

        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Processing Fees</DialogTitle>
                <Typography variant="body1" style={{margin: '16px'}}>A $0.30 flat fee and a 2.9% margin-based fee are
                    applied to each transaction for USA domestic transactions. </Typography>
            </Dialog>
        );
    }

    const checkCharacters = function (text, key) {
        const err = invalidCharacters.some(v => text.includes(v))
        errors[key] = err
        return err
    }
    const checkErrors = function (obj) {
        for (let key in obj) {
            if (obj[key]) {
                error = 'Invalid character in input'
                return false;
            }
        }
        return true;
    }

    const handleClickOpen = () => {
        setFeeModalOpen(true);
    };

    const handleClose = (value) => {
        setFeeModalOpen(false);
    };


    return (
        <div>
            <AppBar position="static" color="default" className={classes.appBar}>
                <Toolbar title="Arup Americas Payments">
                    <Grid container fixed="true">
                        <Grid item xs={4} sm={4}>
                            <div>
                                <img src={'https://d26hekc6mu8k0n.cloudfront.net/arup_logo.png'}
                                     className={classes.media} alt="logo"/>
                            </div>
                        </Grid>
                        <Grid item xs={8} sm={8}>
                            <Typography variant="h6" align="right">
                                Americas Payments
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <form onSubmit={handleSubmit} style={{width: '100%'}} id='payment-form'>
                <Grid container fixed="true" justify="center">
                    <Grid item xs={12} sm={6}>
                        <Grid item className={classes.detailsContainer}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6" gutterBottom>
                                    Billing Information
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <CardElement hidePostalCode={true}/>
                                        <hr/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="cardholder"
                                            name="cardholder"
                                            label="Cardholder Name"
                                            fullWidth
                                            autoComplete="cardholder"
                                            value={values.cardholder}
                                            onChange={handleChange}
                                            error={checkCharacters(values.cardholder, 'cardholder')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="company"
                                            name="company"
                                            label="Company"
                                            fullWidth
                                            autoComplete="company"
                                            value={values.company}
                                            onChange={handleChange}
                                            error={checkCharacters(values.company, 'company')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberFormat
                                            customInput={TextField}
                                            format="+1 (###) ### ####"
                                            type="tel"
                                            placeholder="+1 (###) ### ####"
                                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                            maxLength={10}
                                            id="phone"
                                            name="phone"
                                            label="Phone"
                                            fullWidth
                                            autoComplete="Billing Phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                            error={checkCharacters(values.phone, 'phone')}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            name="email"
                                            label="Email"
                                            fullWidth
                                            autoComplete="Email"
                                            type="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={checkCharacters(values.phone, 'email')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="address_line1"
                                            name="address_line1"
                                            label="Address 1"
                                            fullWidth
                                            autoComplete="billing address-line"
                                            value={values.address_line1}
                                            onChange={handleChange}
                                            error={checkCharacters(values.address_line1, 'address_line1')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="address_line2"
                                            name="address_line2"
                                            label="Address 2"
                                            fullWidth
                                            autoComplete="billing address-line2"
                                            value={values.address_line2}
                                            onChange={handleChange}
                                            error={checkCharacters(values.address_line2, 'address_line2')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            required
                                            id="address_city"
                                            name="address_city"
                                            label="City"
                                            type="text"
                                            fullWidth
                                            autoComplete="billing address-level2"
                                            value={values.address_city}
                                            onChange={handleChange}
                                            error={checkCharacters(values.address_city, 'address_city')}
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <TextField
                                            id="address_state"
                                            name="address_state"
                                            label="State"
                                            fullWidth
                                            maxLength={2}
                                            inputProps={{maxLength: 2}}
                                            value={values.address_state}
                                            required
                                            type="text"
                                            error={checkCharacters(values.address_state, 'address_state') || values.address_state.length > 2}
                                            onChange={handleChange}/>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <TextField
                                            required
                                            id="address_zip"
                                            name="address_zip"
                                            label="Zip Code"
                                            type="number"
                                            fullWidth
                                            max="99999"
                                            inputProps={{max: "99999"}}
                                            autoComplete="billing zip-code"
                                            value={values.address_zip}
                                            onChange={handleChange}
                                            error={checkCharacters(values.address_zip, 'address_zip') || values.address_zip.length > 5}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.paper}>
                                <Typography variant="h6" gutterBottom>
                                    Payment Summary
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="invoice_id"
                                            name="invoice_id"
                                            label="Invoice Number"
                                            fullWidth
                                            type="number"
                                            autoComplete="invoice_id"
                                            inputProps={{maxLength: 12}}
                                            error={values.invoice_id.length > 12 || checkCharacters(values.invoice_id, 'invoice_id')}
                                            value={values.invoice_id}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="job_number"
                                            name="job_number"
                                            label="Job Number"
                                            fullWidth
                                            autoComplete=""
                                            value={values.job_number}
                                            onChange={handleChange}
                                            error={checkCharacters(values.job_number, 'job_number')}
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={6} style={{paddingRight: 0}}>
                                        <TextField
                                            id="amount"
                                            label="Payment Amount"
                                            value={values.amount}
                                            name="amount"
                                            onChange={handleChange}
                                            type="number"
                                            fullWidth
                                            min={0}
                                            max={100000}
                                            inputProps={{
                                                style: {textAlign: 'right'},
                                                readOnly: !!cardToken
                                            }}
                                            error={values.amount !== '' && (values.amount < 0 || values.amount > 100000 || checkCharacters(values.amount, 'amount'))}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={2} sm={1}
                                          style={{paddingLeft: 0, display: "flex", alignItems: "flex-end"}}>
                                        <div style={currencyStyle}>USD</div>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} style={{marginTop: '16px'}}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1" gutterBottom>
                                            Subtotal: ${parseFloat(values.amount || 0).toFixed(2) || 0.00}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1" gutterBottom>
                                            Processing Fee: ${parseFloat(values.fees).toFixed(2) || 0.00}
                                            <IconButton color="primary" onClick={handleClickOpen}>
                                                <HelpOutline/>
                                            </IconButton>
                                            <SimpleDialog open={feemodalopen} onClose={handleClose}/>
                                        </Typography>
                                        {/*<Typography variant="caption">A $0.30 flat fee and a 2.9% margin-based fee are applied to each transaction.</Typography>*/}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Total:
                                                ${(parseFloat(values.amount) + parseFloat(values.fees) || 0).toFixed(2)}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} style={{marginTop: '8px', marginBottom: '8px'}}>
                                    <div>
                                        <Button variant="contained" color="primary" type="submit"
                                                disabled={process.processing || process.complete} size="large">
                                            {process.processing ? 'Processing' : 'Make Payment'} {process.processing}
                                        </Button>
                                        {process.processing &&
                                        <div style={{margin: '8px auto'}}>
                                            <CircularProgress size={32}/>
                                        </div>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography component="span">
                                        {process.failed &&
                                        <div>
                                            <Alert severity="warning">
                                                <AlertTitle>Something went wrong</AlertTitle>
                                                Please check your submission details and try again.
                                                If the problem continues, please check with your banking
                                                institution.<br/><br/>
                                                {error !== null && 'MESSAGE: ' + error}
                                            </Alert>
                                        </div>
                                        }
                                        {process.complete &&
                                        <div>
                                            {/*TODO: Add correct payment info*/}
                                            <Alert severity="success">
                                                <AlertTitle>Payment Successful</AlertTitle>
                                                Thank you! We just sent <a href={response.receipt_url} target="_blank">your
                                                receipt</a> to your email address. If you do not receive an email
                                                receipt, contact payments@arup.com to request a receipt. You can close
                                                this page.</Alert>
                                        </div>
                                        }
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" align='justify'
                                                style={{'paddingTop': '20px'}}>
                                        Arup uses Stripe to process online payments; by clicking "Make Payment" you
                                        agree to our <a href="#" onClick={openModal}>terms of payment</a>.
                                    </Typography>
                                    <Typography variant="caption" align='justify'>
                                        Your payment details are kept secure with <a
                                        href="https://stripe.com/au/guides/pci-compliance" target="_blank">Stripe's PCI
                                        DSS compliant</a> payment technology. Payments will be processed within 2
                                        business days.
                                    </Typography>
                                    <TermsModal modalopen={modalopen} closeModal={closeModal} classes={classes} company={values.company}/>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
};

export default injectStripe(Billing)
