import React from "react"
import ReactDOM from "react-dom"
import getRoot from 'get-root'
import Head from 'react-helmet'
import Main from './components/Main'
import {Grid} from '@material-ui/core'
import {STRIPE_KEY} from './keys'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stripejs: null
        }
    }

    componentDidMount() {
        const stripeKey = STRIPE_KEY;
        const stripeJs = document.createElement('script');
        stripeJs.src = 'https://js.stripe.com/v3/';
        stripeJs.async = true;
        stripeJs.onload = () => {
            this.setState({
                stripejs: window.Stripe(stripeKey),
            });
        };
        document.body && document.body.appendChild(stripeJs)
    }

    render() {
        return (
            <div>
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
                </Head>
                <Grid container direction="row"
                      justify="center"
                      alignItems="center">
                    <Main stripe={this.state.stripejs}/>
                </Grid>
            </div>

        )
    }
}

ReactDOM.render(<App/>, getRoot());
