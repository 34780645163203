import {useState} from 'react'

const useForm = cb => {

    const [values, setValues] = useState({
        cardholder: '',
        phone: '',
        company: '',
        email: '',
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_zip: '',
        address_state: '',
        address_country: 'USA',
        invoice_id: '',
        job_number: '',
        amount: '',
        fees: 0.30,
        total: 0
    });


    const handleSubmit = e => {
        console.log(e)
        if (e) {
            e.preventDefault();
            console.log(e);
            cb()
        }
    };

    const feeCalc = (amount) => {
        const flatFee = 0.3;
        const percent = .029;
        let total_fees=flatFee
        if (amount !== '') {
            total_fees = ((1 / (1 - percent)) * (parseFloat(amount) + flatFee)) - parseFloat(amount);
        }
        return total_fees.toFixed(2)
    };

    const handleChange = e => {
        e.persist();
        setValues(values => ({...values, [e.target.name]: e.target.value}))
        if (e.target.name == 'amount') {
            const fee = feeCalc(e.target.value)
            const total = fee + e.target.value
            setValues(values => ({...values, fees: fee, total: total}))
        }
    };

    return {
        handleChange,
        handleSubmit,
        values,
    }
};

export default useForm
